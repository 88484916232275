import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaFacebookF, FaLinkedinIn, FaEnvelope, FaPhone, FaFax } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                {/* <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form> */}
                <div className="row ">
                    <div className="contact-info-wrap">
                        <div className="contact-inner">
                            <address className="address">
                                <span className="title">Contact Information</span>
                                <p>HQ: Dubai, UAE <br />SUB: Islamabad, PAK</p>
                            </address>
                            <address className="address">
                                <span className="title">We're Available 24/7. Call Now.</span>
                                <a href="tel:+923255516168" className="tel"><FaPhone /> (+92) 325-5516168</a>
                                <a href="tel:+14410206" className="tel"><FaFax /> (+1) 530-8139501</a>
                            </address>
                        </div>
                        <div className="contact-inner">
                            <h5 className="title">Find us here</h5>
                            <div className="contact-social-share">
                                <ul className="social-share list-unstyled">
                                    {/* <li>
                                            <a href="https://facebook.com/"><FaFacebookF /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.behance.net/"><FaBehance /></a>
                                        </li> */}
                                    <li>
                                        <a href="https://www.linkedin.com/company/planitechno"><FaLinkedinIn /></a>
                                    </li>
                                    <li>
                                        <a href="mailto:contact.planitech@gmail.com"><FaEnvelope /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/digital-agency"}>Digital Agency</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/creative-agency"}>Creative Agency</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/personal-portfolio"}>Personal Portfolio</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/home-startup"}>Home Startup</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/corporate-agency"}>Corporate Agency</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                    </div> */}
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
