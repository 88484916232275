import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import CounterUp from '../../component/counterup/CounterUp';


const CounterUpOne = () => {
    return (
        <div className="section section-padding bg-color-dark">
            <div className="container">
                <SectionTitle
                    subtitle="Featured Case Study"
                    title="The Startup Movement"
                    description="We Integrate Intellegence even into the smallest components, which defines our uniqueness in the market. Our products range plays a diverse role in blooming the commercial operations."
                    textAlignment="heading-light"
                    textColor=""
                />
                <div className="row">
                    <CounterUp colSize="col-lg-3 col-6" layoutStyle="" evenTopMargin="" />
                </div>
            </div>
        </div>
    )
}


export default CounterUpOne;